import {config} from "src/config.js";
import {useCallback, useState} from "react";

/*
function handleMessage(eventType, data) {
    switch (eventType) {
    case "connected":
        console.log("Connected to stream:", data);
        break;
    case "message":
        console.log("Received message:", data);
        // Handle the message data here
        break;
    default:
        console.log("Received data:", data);
    }
}

function processChunk(chunk) {
    console.log("Processing chunk:", chunk);

    // Split the chunk into individual messages
    const messages = chunk.split("\n\n").filter((msg) => msg.trim() !== "");

    messages.forEach((message) => {
        const lines = message.split("\n");
        let eventType = "message"; // Default event type
        let data = "";

        lines.forEach((line) => {
            if (line.startsWith("event:")) {
                eventType = line.replace("event:", "").trim();
            } else if (line.startsWith("data:")) {
                data = line.replace("data:", "").trim();
            } else {
                // If the line doesn't start with 'event:' or 'data:', treat it as data
                data += line.trim();
            }
        });

        handleMessage(eventType, data);
    });
}
*/

export function startStream(sessionId, onNewMessage, onCompleted) {
    const streamUrl = `${config.API_URL}/v1/stream/${sessionId}`;

    return fetch(streamUrl).then((response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        function readStream() {
            reader.read().then(({done, value}) => {
                if (done) {
                    console.log("Stream complete");
                    if (onCompleted) {
                        onCompleted();
                    }
                    return;
                }

                const chunk = decoder.decode(value, {stream: true});
                // console.log("Read chunk:", chunk);
                onNewMessage(chunk);
                // processChunk(chunk);
                readStream();
            }).catch((error) => {
                console.error("Error reading stream:", error);
            });
        }

        readStream();
    }).catch((error) => {
        console.error("Error starting stream:", error);
    });
}

export function useStreaming(sessionId) {
    const [isOpen, setIsOpen] = useState(false);
    const [streamedContent, setStreamedContent] = useState("");

    const initStream = useCallback(() => {
        setIsOpen(true);
        setStreamedContent("");
        const onNewMessage = (chunk) => {
            setStreamedContent((prevContent) => prevContent + chunk);
        };
        const onCompleted = () => {
            setIsOpen(false);
        };
        return startStream(sessionId, onNewMessage, onCompleted);
    }, [sessionId]);

    return [initStream, {content: streamedContent, isOpen}];
}
