import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Markdown from "react-markdown";

export function MarkdownContent({content}) {
    return (
        <Wrapper>
            <Markdown>
                {content}
            </Markdown>
        </Wrapper>
    );
}

MarkdownContent.propTypes = {
    content: PropTypes.string
};

const Wrapper = styled.div`
  & h1, h2, h3, h4 {
    font-weight: bold;
  }
  & h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  & h2 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
  & h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  & h4 {
    font-size: 1rem;
  }
  & p {
    margin-bottom: 1rem;
  }
  & ul {
    margin-bottom: 1rem;
  }

  & ul li {
    list-style-type: square;
    margin-left: 16px;
    margin-bottom: 4px;
  }

  & ol {
    margin-bottom: 1rem;
  }

  & ol li {
    list-style-type: decimal;
    margin-left: 16px;
    margin-bottom: 4px;
  }

  & code {
    font-family: monospace;
  }
  
  & pre {
    padding: 1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
  }
`;
