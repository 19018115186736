import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useAskQuestion} from "src/api/chat/useAskQuestion.js";
import {useGetChatSession} from "src/api/chat/useGetChatSession.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {Navigate} from "react-router-dom";
import {LINKS} from "src/links.js";
import {useDataSidebarState} from "src/pages/Home/pages/TablePage/pages/ChatPage/data_sidebar_context.jsx";
import {Title} from "src/primitives/title.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {useStreaming} from "./useStream.jsx";
import {ChatSessionData} from "./components/ChatSessionData/index.jsx";
import {chatMessageWrapperClass, ChatSessionHistory} from "./components/ChatSessionHistory/index.jsx";
import {PromptArea} from "./components/PrompArea/index.jsx";
import {ChatSessionHeader} from "./components/ChatSessionHeader/index.jsx";


export function ChatSessionPage({sessionId, tableId}) {
    const dummyRef = useRef(null);
    const timerRef = useRef(null);
    const sidebarState = useDataSidebarState();
    const getLink = useGetLink();
    const {showError} = useShowToast();
    const [askQuestion, {loading}] = useAskQuestion(sessionId);
    const {session, loading: loadingSession} = useGetChatSession(sessionId);
    const [initStream, {content: streamedContent, isOpen}] = useStreaming(sessionId);

    const doAskQuestion = (question) => {
        initStream(sessionId);
        askQuestion(question).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((error) => {
            console.error(error);
            showError({message: "Failed to ask question"});
        });
    };

    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (dummyRef.current) {
                dummyRef.current.scrollIntoView({behavior: "smooth"});
            }
        }, 250);

        return () => {
            if (timer) {
                window.clearTimeout(timer);
            }
        };
    }, [session?.history?.length]);

    useEffect(() => {
        if (isOpen) {
            timerRef.current = window.setInterval(() => {
                console.log("scroll");
                if (dummyRef.current) {
                    dummyRef.current.scrollIntoView({behavior: "smooth"});
                }
            }, 500);
        } else if (!isOpen && timerRef.current) {
            window.clearInterval(timerRef.current);
            timerRef.current = null;
        }

        return () => {
            if (timerRef.current) {
                window.clearImmediate(timerRef.current);
            }
        };
    }, [isOpen]);

    if (!session && !loadingSession) {
        return <Navigate to={getLink(LINKS.tableChat(tableId))}/>;
    }

    if (loadingSession) {
        return (
            <div className="full flex justify-center items-center">
                <div className="flex flex-col gap-4 w-112 mx-auto">
                    <Title>Loading chat session</Title>
                    <Spinner/>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="flex-1 w-full h-full relative pb-28 pt-16">
                <ChatSessionHeader session={session}/>

                <div className="h-full w-full overflow-y-auto custom-scrollbar">
                    <div className={sidebarState ? "w-[50%]" : "w-full"}>
                        <div className="px-8 mb-4">
                            <div className="w-176 max-w-full mx-auto">
                                <ChatSessionHistory session={session} preview={loading ? streamedContent : null}/>
                                {(loading && !streamedContent) && (
                                    <div className="my-4">
                                        <div className={chatMessageWrapperClass()}>
                                            <div className="flex items-center gap-4">
                                                <Spinner size="sm"/>
                                                <div className="text-neutral-600">Loading answer</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div ref={dummyRef} className="h-12"/>
                    </div>
                </div>

                <PromptAreaWrapper className="absolute bottom-0 w-full">
                    <PromptArea
                        askQuestion={doAskQuestion}
                        loading={loading}
                    />
                </PromptAreaWrapper>

                <div
                    /* eslint-disable-next-line max-len */
                    className="absolute right-12 top-16 bottom-32 w-176 max-w-[45%] z-index-10 overflow-hidden backdrop-blur-sm"
                    style={{display: sidebarState ? "block" : "none"}}
                >
                    <ChatSessionData data={session?.data}/>
                </div>
            </div>
        </>
    );
}

ChatSessionPage.propTypes = {
    sessionId: PropTypes.string.isRequired,
    tableId: PropTypes.string.isRequired
};

const PromptAreaWrapper = styled.div`
  /*  backdrop-filter: blur(1px); */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
`;
