import React from "react";
import {TextLink} from "src/primitives/TextLink.jsx";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";


export function NewFileInstructions() {
    const getLink = useGetLink();

    return (
        <div className="flex flex-col gap-4 text-base">
            <div>
                {/* eslint-disable-next-line max-len */}
                Go to the <TextLink to={getLink(LINKS.UPLOAD)}>new file page</TextLink> and follow the instructions to upload your first file.
            </div>
        </div>
    );
}
