import React from "react";
import PropTypes from "prop-types";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {ChatDataContextProvider} from "src/pages/Home/pages/TablePage/pages/ChatPage/chat_data_context.jsx";
import {DataSidebarContextProvider} from "src/pages/Home/pages/TablePage/pages/ChatPage/data_sidebar_context.jsx";
import {StartNewChatPage} from "./pages/StartNewChat";
import {ChatSessionPage} from "./pages/ChatSession";


function RenderChatPage() {
    const {sessionId, tableId} = useParams();

    if (!sessionId) {
        return <Navigate to="" />;
    }

    return (
        <ChatSessionPage sessionId={sessionId} tableId={tableId}/>
    );
}


export function TableChatPage({tableId}) {
    return (
        <DataSidebarContextProvider>
            <ChatDataContextProvider>
                <Routes>
                    <Route
                        path=""
                        element={<StartNewChatPage tableId={tableId}/>}
                    />
                    <Route
                        path="/:sessionId"
                        element={<RenderChatPage/>}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="" />}
                    />
                </Routes>
            </ChatDataContextProvider>
        </DataSidebarContextProvider>
    );
}

TableChatPage.propTypes = {
    tableId: PropTypes.string.isRequired
};
