import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {Navigate} from "react-router-dom";
import {useStartChat} from "src/api/chat/useStartChat.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {ContentEditableDiv} from "src/primitives/controls/ContentEditable.jsx";
import {Title} from "src/primitives/title.jsx";
import {useGetChatSessions} from "src/api/chat/useGetChatSessions.js";
import {ExistingSessions} from "./components/ExistingSessions/index.jsx";


export function StartNewChatPage({tableId}) {
    const {sessions, loading: loadingSessions} = useGetChatSessions(tableId);

    const getLink = useGetLink();
    const [value, setValue] = useState("");
    const [startSession, {loading, error, sessionId}] = useStartChat(tableId);

    if (sessionId) {
        return <Navigate to={getLink(LINKS.tableChatSession(tableId, sessionId))}/>;
    }

    return (
        <div className="full overflow-auto">
            <div className="flex flex-col gap-4 w-112 mx-auto pt-40 pb-20">
                <div className="flex flex-col gap-2 mb-8">
                    <Title>Start new chat</Title>
                    <div className="relative">
                        <ContentEditableDiv
                            className="rounded-md p-2 text-neutral-800 border border-neutral-200 min-h-36"
                            onChange={setValue}
                        />
                        {value === "" ? (
                            <div className="absolute inset-0 p-2 text-neutral-500 inert">
                                Ask me anything...
                            </div>
                        ) : null}
                    </div>
                    <Button
                        onClick={startSession}
                        isLoading={loading}
                        theme="secondary"
                    >
                        Start new session
                    </Button>

                    {error && (
                        <div>
                            Failed to start new session
                        </div>
                    )}
                </div>

                <ExistingSessions sessions={sessions} loading={loadingSessions}/>
            </div>
        </div>
    );
}


StartNewChatPage.propTypes = {
    tableId: PropTypes.string.isRequired
};
